<template>
  <div>
    <v-card-title>
      Teilnehmer {{ index? '#' + (index+1): '' }}
      <v-btn v-show="index > 0" color="red" style="margin-left: 20px" @click="removeParticipant(value.id)" fab dark small><v-icon>mdi-delete</v-icon></v-btn>
    </v-card-title>
    <v-container class="participant">
      <v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" xs="12">
              <v-text-field v-model="value.firstname" :rules=inputRequiredRule label="Vorname" dense required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12">
              <v-text-field v-model="value.lastname" :rules=inputRequiredRule label="Nachname" dense required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12">
              <v-text-field v-model="value.street" label="Straße und Hausnummer" dense required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field v-model="value.zipcode" label="PLZ" dense required></v-text-field>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field v-model="value.city" label="Stadt" dense required></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" xs="12">
<!--              <v-text-field v-model="birthdate" :rules=inputRequiredRule label="Geburtsdatum" dense required></v-text-field>-->
<!--
              <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="birthdate" label="Geburtsdatum" prepend-icon="mdi-calendar" dense readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker ref="picker" v-model="birthdate" :max="new Date().toISOString().substr(0, 10)" min="1950-01-01" @change="save">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                </v-date-picker>
              </v-menu>
-->
              <v-dialog ref="dialog" v-model="modal" :return-value.sync="value.birthdate" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="value.birthdate" :rules=dateRules label="Geburtsdatum" prepend-icon="mdi-calendar" dense required v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="value.birthdate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(value.birthdate)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12">
              <v-text-field v-model="value.phone" :rules=inputRequiredRule label="Mobilfunk / SMS" prepend-icon="mdi-phone" dense required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12">
              <v-text-field v-model="value.email" :rules="emailRules" label="E-Mail" prepend-icon="mdi-email" dense required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12">
              <v-select v-model="value.reason" :items="reasons" :rules="[v => !!v || 'Item is required']" label="Grund der Kursteilnahme" dense required></v-select>
            </v-col>
          </v-row>
        </v-col>
        
      </v-row>

    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'Participant',
    props: {
      value: Object,
      index: Number
    },
    data: () => ({
      reasons: [{value:'F', text:'Führerschein'}, {value:'S', text:'Studium oder Ausbildung'}, {value:'B', text:'Beruf'}, {value:'?', text:'Sonstiges'}],
      emailRules: [
        v => !!v || 'E-Mail Adresse benötigt',
        v => /.+@.+\..+/.test(v) || 'Bitte gültige E-Mail eingeben',
      ],
      dateRules: [
        v => !!v || 'Eingabe benötigt',
        v => /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(v) || 'Bitte gültiges Datum eingeben',
      ],
      inputRequiredRule:[v => !!v || 'Eingabe benötigt'],
      modal: false
    }),
    methods: {
      removeParticipant(id) {
        console.log('sending message up to remove participant with id ', id);
        this.$emit('remove', id)      
      }
    }
  }
</script>
