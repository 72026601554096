<template>
  <div class="booking">
    <h1>Erste-Hilfe Kurs online buchen</h1>

    <div v-if="loading" class="loading">
      Loading...
    </div>

    <div v-if="error" class="error">
      {{ error }}
    </div>

    <div v-if="course" class="content">
      <p>
        Ausgewählter Kurs: 
        {{ (Date.parse(course.datum) != null)? daysOfWeek.get(new Date(course.datum).getDay()).long : '' }},
        {{ course.zeit_von }} - {{ course.zeit_bis }} Uhr<br>
        Kursort: {{ filialen.get(course.filialen_id) }}<br>
        Preis: {{ course.preis }},- Euro &middot; Sprache: {{ course.sprache }}
<!--
        Kurs-Nummer: {{ this.$route.params.courseId }}<br/>
        Datum: {{ (Date.parse(course.datum) != null)? daysOfWeek.get(new Date(course.datum).getDay()).long : '' }},
        {{ (Date.parse(course.datum) != null)? new Date(course.datum).toLocaleDateString() : course.datum }}<br/>
        Zeit: {{ course.zeit_von }} - {{ course.zeit_bis }}<br/>
        Kursort: {{ filialen.get(course.filialen_id) }}<br/>
        Preis: {{ course.preis }},- Euro<br/>
        Teilnehmer: {{ participants }}<br/>
        <gb-flag :code="course.sprache" size="small" />
-->
      </p>
    </div>
    <v-card outlined>

      <v-form ref="bookingForm" v-model="valid">
        <participant v-for="(participant, index) in participants" v-bind:key="index" :index="index" :value="participant" @remove="removeParticipant" :id="participant.id" />

<!--
        <v-container class="participant">
          <v-row>
            <v-col cols="12" md="6">
              <v-checkbox v-model="acceptTerms" :rules="[v => !!v || 'Sie müssen den AGB zustimmen um diesen Kurs buchen zu können!']" required>
                <template v-slot:label>
                  <div>
                    Hiermit akzeptiere ich die
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a target="_blank" href="/agb.html" @click.stop v-on="on">AGB</a>
                      </template>
                      AGB in neuem Fenster/Tab öffnen
                    </v-tooltip>
                    der ASH.
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-container>
-->

        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col cols="12" md="4">
              <v-btn color="warning" block :to="{name: 'Home'}">Zurück zu den Kursen</v-btn>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn color="primary" block @click="addParticipant">Weiteren Teilnehmer hinzufügen</v-btn>
            </v-col>
            <v-col cols="12" md="4">
              <!--<v-btn color="success" @click="processBooking" :disabled="!valid">Weiter zur Bezahlung</v-btn>-->
              <v-btn color="success" block @click="proceedToCheckout" :disabled="!valid">Weiter zur Bezahlung</v-btn>
              <!-- :to="{ name: 'BookingCheckout', params: { course: this.course, participants: this.participants } }" -->
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
    
<!-- <p>{{ course }}</p> -->

  </div>
</template>

<script>
import Participant from '@/components/Participant.vue'
import axios from 'axios'
import jsonFilialen from "@/assets/data/filialen.json";
import jsonWeekdays from "@/assets/data/weekdays.de.json";

export default {
  name: 'BookingNew',
  components: {
    Participant
  },
/*
  props: {
    course: {
      type: Object,
      default: null
    },
    participants: { 
      type: Array,
      default: () => [] 
    }
  },
*/
  data: () => ({
    valid: false,
    loading: false,
    error: null,
    filialen: new Map(jsonFilialen),
    daysOfWeek: new Map(jsonWeekdays),
    course: null,
    participants: [],
//    acceptTerms: false,
  }),
  created () {
    if (this.$route.params.course != null) {
      this.course = this.$route.params.course;
    } else {
      this.fetchData();
    }
    if (this.$route.params.participants) {
      this.participants = this.$route.params.participants;
    } else {
      this.participants = [];
      this.addParticipant();
    }
//    this.fetchData()
//    this.participants.push(this.newParticipant())
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    fetchData() {
      this.error = this.course = null
      this.loading = true
      const fetchedId = this.$route.params.courseId
      // TODO: remove switchcase
      let target = (process.env.NODE_ENV === 'development')? "http://localhost:3000/api/kurs/" : "/api/kurs/"
//      let target = (process.env.NODE_ENV === 'development')? "/data/kurs.json?id=" : "/api/kurs/"
//      axios.get("/data/kurs.json?skip=" + fetchedId)
//      axios.get("/api/kurs/" + fetchedId)
      axios.get(target + fetchedId)
      .then(response => {this.course = response.data; this.loading = false; })
      .catch(error => {this.error = error; this.loading = false; });
/*
      // see: https://router.vuejs.org/guide/advanced/data-fetching.html#fetching-after-navigation
      getCourse(fetchedId, (err, course) => {
        // make sure this request is the last one we did, discard otherwise
        if (this.$route.params.id !== fetchedId) return
        this.loading = false
        if (err) {
          this.error = err.toString()
        } else {
          this.course = course
        }
      })
*/
    },
    newParticipant() {
      return {
        id: Math.floor(Math.random() * 100000) // random number used to address components for removal
      }
    },
    addParticipant() {
      this.participants.push(this.newParticipant())
    },
    removeParticipant(id) {
      // remove a participant; in combination with: @remove="removeParticipant" :id="participant.id"
      // participant component has a remove button, which emits the 'remove'event, which then is
      // redirected to this method
      const index = this.participants.findIndex(p => p.id === id)
      this.participants.splice(index, 1)     
    },
    proceedToCheckout() {
      this.$router.push( {
        name: 'BookingCheckout',
        params: {
          course: this.course,
          participants: this. participants

        }
      });
    },
/*
    processBooking() {
      alert("process booking");
      this.loading = true;
      const booking = {
//        kursId: this.kursId,
//        kursId: this.course.id,
        participants: this.participants,
        acceptTerms: this.acceptTerms
      }
      axios.post("http://localhost:3000/prepare-booking/", booking)
        .then(response => { alert("Booking confirmed: " + response.data); this.loading = false; })
        .catch(error => {this.error = error; this.loading = false; });
    }
*/
/*
    async processBooking() {
      this.loading = true;
      const booking = {
        kursId: this.course.id,
        participants: this.participants,
        acceptTerms: this.acceptTerms
      }
      try {
        const response = await axios.post("http://localhost:3000/prepare-booking/", booking);
        console.log("Response = " + response.status + ": " + response.statusText)
        alert("Booking confirmed: " + response.data); 
        if (response.data && response.data.redirect) {
          window.location.href = response.data.redirect;
        }
      } catch(err) {
        console.log(err)
        alert(err)
      }
      this.loading = false;
    }
*/
  }
}
</script>

<style scoped>
  @import '../assets/css/main.css';
</style>