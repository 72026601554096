<template>
  <div class="booking-checkout">
    <h1>Kurs buchen - Bezahlung</h1>

    <loading :active.sync="loading" :can-cancel="false" :on-cancel="onCancel" :is-full-page="true"></loading>
<!--
    <div v-if="loading" class="loading">
      Loading...
    </div>
-->

    <div v-if="error" class="error">
      {{ error }}
    </div>

    <div v-if="course" class="content">
      <p>
        Bitte prüfen Sie die Details Ihrer Buchung. Erst nach erfolgreicher Zahlung ist die Buchung gültig.
      </p>
    </div>
    <v-card outlined>

      <v-form ref="bookingForm" v-model="valid">

        <div class="invoice-content">
          <div class="table-responsive">
              <table class="table table-invoice">
                <thead>
                  <tr>
                    <th>BESCHREIBUNG</th>
                    <th class="text-center" width="10%">TEILNEHMER</th>
                    <th class="text-right" width="20%">BETRAG</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="participant in participants" :key="participant.id">
                    <td>
                      <span class="text-inverse">
                        Erste-Hilfe Kurs am {{ (Date.parse(course.datum) != null)? daysOfWeek.get(new Date(course.datum).getDay()).long : '' }},
                        {{ (Date.parse(course.datum) != null)? new Date(course.datum).toLocaleDateString() : course.datum }}
                        von {{ course.zeit_von }} - {{ course.zeit_bis }} Uhr<br>
                      </span>
                      <small>Teilnehmer: {{ participant.lastname }}, {{ participant.firstname }}</small>
                    </td>
                    <td class="text-center">1</td>
                    <td class="text-right">&euro; {{ Number.parseFloat(course.preis).toFixed(2) }}</td>
                  </tr>
                </tbody>
              </table>
          </div>
          <div class="invoice-price">
              <div class="invoice-price-left"></div>
              <div class="invoice-price-right">
                <small>Gesamtbetrag</small> <span class="f-w-600">&euro; {{ totalAmount.toFixed(2) }}</span>
              </div>
          </div>
        </div>

        <v-container class="participant">
          <v-row>
            <v-col cols="12" md="6">
              <v-checkbox v-model="acceptTerms" :rules="[v => !!v || 'Sie müssen den AGB zustimmen um diesen Kurs buchen zu können!']" required>
                <template v-slot:label>
                  <div>
                    Hiermit akzeptiere ich die
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a target="_blank" href="agb.html" @click.stop v-on="on">AGB</a>
                      </template>
                      AGB in neuem Fenster/Tab öffnen
                    </v-tooltip>
                    der ASH.
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-container>

        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col cols="12" md="4">
              <v-btn color="warning" block @click="returnToParticipants">Zurück zu den Teilnehmer</v-btn>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn color="success" block @click="proceedToPayment" :disabled="(!valid)||loading">Mit Paypal bezahlen</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>

  </div>
</template>

<script>
import axios from 'axios'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import jsonFilialen from "@/assets/data/filialen.json";
import jsonWeekdays from "@/assets/data/weekdays.de.json";

export default {
  name: 'BookingCheckout',
  components: {
    Loading
  },
  data: () => ({
    valid: false,
    loading: false,
    error: null,
    filialen: new Map(jsonFilialen),
    daysOfWeek: new Map(jsonWeekdays),
    course: null,
    participants: [],
    totalAmount: 0.00,
    acceptTerms: false,
  }),
  created () {
    this.course = this.$route.params.course;
    this.participants = this.$route.params.participants;
    if (this.course == null || this.participants == null) {
      this.returnToParticipants()
    } else {
      this.calculateTotalAmount()
    }
  },
//  watch: {
    // call again the method if the route changes
//    '$route': 'fetchData'
//  },
  methods: {
    returnToParticipants() {
      if (this.course) {
        this.$router.push( {
          name: 'BookingNew',
          params: {
            courseId: this.course.id,
            course: this.course,
            participants: this. participants
          }
        });
      } else {
        this.$router.push( {
          name: 'Home'
        });
      }
    },
    calculateTotalAmount() {
      this.totalAmount = this.participants.length * Number.parseFloat(this.course.preis);
//      for (i = 0; i < this.participants.length; ++i) {
//        let p = this.participants[i];
//        this.totalAmount += p.price
//      }
    },
    async proceedToPayment() {
      this.loading = true;
      const booking = {
        kursId: this.course.id,
        participants: this.participants,
        acceptTerms: this.acceptTerms
      }
      try {
        // TODO: remove switchcase
        let target = (process.env.NODE_ENV === 'development')? "http://localhost:3000/prepare-booking/" : "/checkout/payment/";
//        const response = await axios.post("http://localhost:3000/prepare-booking/", booking);
//        const response = await axios.post("/checkout/payment/", booking);
        const response = await axios.post(target, booking);
        console.log("Response = " + response.status + ": " + response.statusText)
        if (response.data && response.data.redirect) {
          window.location.href = response.data.redirect;
        } else {
          this.loading = false;
        }
      } catch(err) {
        console.log(err)
        alert(err)
        this.loading = false;
      }
    },
    onCancel() {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style scoped>
  @import '../assets/css/main.css';
  @import '../assets/css/invoice.css';
/*
  div.loading {
    opacity:    0.5; 
    background: #000; 
    width:      100%;
    height:     100%; 
    z-index:    10;
    top:        0; 
    left:       0; 
    position:   fixed; 
  }
  */
</style>